"use client";

import { ChevronLeft, ChevronRight } from "@squaredmade/icons";
import type * as React from "react";
import { DayPicker } from "react-day-picker";

import { buttonVariants } from "@/components/ui/button";
import { cn } from "@/utils/cn";

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

const Chevron = ({ orientation = "left", ...props }) => {
	return orientation === "left" ? (
		<ChevronLeft className="relative z-10 size-4" {...props} />
	) : (
		<ChevronRight className="relative z-10 size-4" {...props} />
	);
};

function Calendar({
	className,
	classNames,
	showOutsideDays = true,
	...props
}: CalendarProps) {
	return (
		<DayPicker
			mode="single"
			showOutsideDays
			className={cn("pointer-events-auto p-3", className)}
			classNames={{
				months: "flex flex-col sm:flex-row space-y-4 sm:space-y-0",
				month: "space-y-4",
				month_caption: "flex justify-center pt-1 relative items-center",
				caption_label: "text-sm font-medium",
				chevron: "group-hover:stroke-white",
				nav: "space-x-1 flex items-center",
				button_previous: cn(
					buttonVariants({ variant: "outline" }),
					"h-7 w-7 absolute top-3.5 left-[22px] z-20 bg-transparent p-0 opacity-50 hover:opacity-100 group",
				),
				button_next: cn(
					buttonVariants({ variant: "outline" }),
					"h-7 w-7 absolute top-3.5 right-[22px] z-20 bg-transparent p-0 opacity-50 hover:opacity-100 group",
				),
				month_grid: "w-full border-collapse space-y-1",
				weekdays: "flex",
				weekday:
					"text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]",
				week: "flex w-full mt-2",
				day: "h-9 w-9 text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
				day_button: cn(
					buttonVariants({ variant: "ghost" }),
					"h-9 w-9 p-0 font-normal aria-selected:opacity-100",
				),
				range_end: "day-range-end",
				selected:
					"bg-primary rounded-md text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground",
				today: "bg-accent rounded-md text-accent-foreground",
				outside:
					"day-outside text-muted-foreground opacity-50 aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30",
				disabled: "text-muted-foreground opacity-50",
				range_middle:
					"aria-selected:bg-accent aria-selected:text-accent-foreground",
				hidden: "invisible",
				...classNames,
			}}
			components={{
				Chevron,
			}}
			{...props}
		/>
	);
}
Calendar.displayName = "Calendar";

export { Calendar };
